.headerContainer {
  height: 65px;
  background-color: #3783c5;
  display: flex;
  align-items: center;
  position: relative;
}
.logo {
  height: 25px;
  margin: 25px;

  cursor: pointer;
}

.logoHeader {
  height: 40px;
  color: white;
}

.headerSubHeaderContainer {
  /* float: left; */
  margin-left: 20px;
}
.subHeaderTop {
  color: white;
  /* margin-top: 30px; */
  /* line-height: 0px; */
}
.subHeaderBottom {
  font-weight: bolder;
  color: white;
  padding-left: 20px;
  line-height: 0.5px;
}

.logoCustomer {
  height: 40px;
  /* float: right; */
  align-self: flex-end;
  /* margin-top: 12px; */
  margin-right: 40px;
}

.left {
  flex: 5;
  display: flex;
  align-items: center;
}

.right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 25px;
}

.contextButton {
  float: left;
  height: 30px;
  cursor: pointer;
}

.dividerButton {
  height: 65px;
}

.contextTextContainer {
  margin-right: 35px;
  margin-left: 35px;
  color: white;
}

.contextTextContainerLink {
  margin-right: 35px;
  margin-left: 35px;
  color: white;
  cursor: pointer;
}

.contextMenu {
  position: absolute;
  top: 57px;
  left: 50px;
  height: 200px;
  background: white;
  z-index: 10000;
  padding: 5px 20px 5px 20px;
  border: 1px solid lightgray;
  user-select: none;
}

.contextMenuArrow {
  position: absolute;
  top: 51px;
  left: 264px;
  height: 12px;
  width: 12px;
  background: white;
  z-index: 10001;
  transform: rotate(45deg);
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
}

.itemContainer {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  /* display: flex;
  align-items: center;
  justify-content: space-around; */
  cursor: pointer;
}

.radioButton {
  cursor: pointer;
}

.dropdownPanelList {
  display: flex;
  justify-content: left;
  align-items: center;
}

.headerNavText {
  cursor: pointer;
}

.itemImg {
  background-color: #3783c5;
  border: 4px solid #3783c5;
}

.itemText {
  color: #3783c5;
  font-size: 1.05em;
  font-weight: bold;
  text-align: center;
  line-height: 2;
}

.itemTextSelected {
  color: #646a6c;
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
  line-height: 2;
}

.imgSelected {
  background-color: white;
  border: 4px solid #646a6c;
}

.overlay {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.dropdownContentContainer {
  background: white;
  width: 100%;
  text-align: center;
  white-space: nowrap;
}
.dropdownContentContainer:hover {
  background: lightgray;
}

.dropdownMenuContent {
  color: #3783c5;
  font-size: 1.05em;
  font-weight: bold;
  text-align: center;
  line-height: 2;
  margin-left: 2px;
  margin-right: 2px;
}

.dropdownMenu {
  position: absolute;
  top: 22px;
  left: -50px;
  background: white;
  z-index: 10000;
  border: 1px solid lightgray;
  min-width: 250px;
  padding-top: 8px;
}

.dropdownMenuArrow {
  position: absolute;
  top: 17px;
  left: 65px;
  height: 12px;
  width: 12px;
  background: white;
  z-index: 10001;
  transform: rotate(45deg);
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
}

.dropdown {
  position: relative;
}
.devHeader {
  color: white;
  font-weight: bolder;
  font-size: 16px;
  margin: 10px;
}
