.flagContainer {
  margin-left: 10px;
  padding: 10px;
  position: relative;
  width: 120px;
  user-select: none;
  white-space: nowrap;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header:hover {
  cursor: pointer;
  text-decoration: underline;
}

.headerLabel {
  padding-left: 10px;
  padding-right: 10px;
}

.headerLabelContainer {
  text-align: left;
}

.list {
  position: absolute;
  background-color: #f6f6f6;
  border: 1px solid #888888;
  border-radius: 5px;
  right: 0px;
  top: 35px;
  padding: 5px;
  z-index: 16;
}

.option {
  padding: 5px;
  white-space: nowrap;
}

.option:hover {
  cursor: pointer;
  background-color: #a0a0a0;
  text-decoration: underline;
  border-radius: 5px;
}

.icon {
  height: 14px;
  clip-path: circle(7px at center);
}

.label {
  padding-left: 15px;
  padding-right: 15px;
}

.chevron {
  top: -5px;
  right: 11px;
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #f6f6f6;
  border-top: 1px solid #888888;
  border-left: 1px solid #888888;
  transform: rotate(45deg);
}

/* .dropdownArrow {
  position: relative;
} */
