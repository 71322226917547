.autocompleteContainer {
  display: flex;
  flex: 1;
  position: relative;
}

.inputFormsContainer {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
}

.selectedIcon {
  margin-bottom: 4px;
  width: 24px;
}

.icon {
  width: 18px;
}

.countries {
  width: 90px;
  display: flex;
}

.countryReadonly {
  width: 24px;
  margin: 3px;
}

.country {
  width: 24px;
  margin: 3px;
}

.country:hover {
  cursor: pointer;
}

.successContainer {
  display: flex;
  padding-top: 24px;
  position: relative;
}

.selectedEmployer {
  height: 24px;
  padding-top: 4px;
  color: darkgray;
  display: flex;
}

.selectedEmployerText {
  width: 190px;
}

.orgNumberInvalid {
  width: 280px;
  border: 1px solid lightpink;
  background: #f5f5f5;
  height: 25px;
  padding-left: 15px;
  color: darkcyan;
}
.nameInvalid {
  width: 280px;
  border: 1px solid lightpink;
  background: #f5f5f5;
  height: 25px;
  padding-left: 15px;
  color: darkcyan;
  margin-top: 2px;
}

.orgNumberValid {
  width: 280px;
  border: 1px solid white;
  background: #f5f5f5;
  height: 25px;
  padding-left: 15px;
  color: darkcyan;
}
.nameValid {
  width: 280px;
  border: 1px solid white;
  background: #f5f5f5;
  height: 25px;
  padding-left: 15px;
  color: darkcyan;
  margin-top: 2px;
}

.autocompleteSuggestionsContainer {
}

.autocompleteEntry {
  cursor: pointer;
}

.autocompleteEntryHighlighted {
  background: darkgray;
  cursor: pointer;
}

.autocompleteEntry:hover {
  background: lightgray;
}

.autocompleteEntryHighlighted {
  background: lightgray;
}

.autocompleteEntryText {
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-indent: 0.2em;
}

.autocompleteEntryAddress {
  user-select: none;
  font-size: 0.9em;
  text-indent: 2em;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0.5em;
}

.dropDownStyle {
  position: absolute;
  min-width: 300px;
  max-width: 500px;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid black;
  background: white;
  z-index: 1000;
  padding: 3px;
}

.loadingSpinnerContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 5px;
  height: 75px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
