.debugArea {
  display: flex;
  flex-direction: column;
  height: 500px;
}

.debugOuter {
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  z-index: 1000;
}

.inputArea {
  flex: 1;
}
