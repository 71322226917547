.ModalBackgroundTinter {
  position: absolute;
  z-index: 1001;
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.Modal {
  position: absolute;
  z-index: 1001;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.4);
  background: white;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  -webkit-animation: fadein 0.2s ease 0s normal;
  animation: fadein 0.2s ease 0s normal;
}
@-webkit-keyframes fadein {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadein {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.ThreeRowLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ThreeRowLayoutHeader {
}

.ThreeRowLayoutFooter {
}

.ThreeRowLayoutContent {
  flex: 1;
  overflow: auto;
}
