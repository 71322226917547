.editIndicatorBarContainer {
  width: 100%;
  height: 55px;
  background: #3783c5;
}

.footerContainer {
  width: 100%;
  display: flex;
  background: white;
  justify-content: space-between;
}

.centerButtons {
  display: flex;
  justify-content: center;
}

.header {
  float: left;
  color: white;
  margin: 17px;
  font-weight: 700;
}

.absoluteBottom {
  position: absolute;
  bottom: 0;
}

.buttonRed {
  color: white;
  margin: 12px;
  height: 30px;
  border: 2px solid rgb(226, 91, 42);
  border-radius: 8px;
  width: 140px;
  background: rgb(219, 90, 90);
  text-align: center;
  cursor: pointer;
}

.headerCenter {
  color: white;
  padding-top: 17px;
  font-weight: 700;
  text-align: center;
}

.buttonClose {
  float: right;
  color: white;
  margin: 12px;
  height: 15px;
  width: 15px;
  text-align: center;
  cursor: pointer;
}

.headerImage {
  height: 55px;
  margin-left: 20px;
  float: left;
}

.text {
  margin-top: 3px;
  pointer-events: none;
}

.buttonBlue {
  color: white;
  background: #3783c5;
  margin: 12px;
  height: 30px;
  border: 2px solid #3783c5;
  border-radius: 8px;
  width: 140px;
  text-align: center;
  cursor: pointer;
}

.button {
  float: right;
  color: white;
  margin: 12px;
  margin-right: 30px;
  height: 30px;
  border: 2px solid white;
  border-radius: 8px;
  width: 140px;
  text-align: center;
  cursor: pointer;
}

.buttonDisabled {
  color: lightgray;
  margin: 12px;
  height: 30px;
  border: 2px solid lightgray;
  border-radius: 8px;
  width: 140px;
  text-align: center;
  cursor: not-allowed;
}

.text {
  margin-top: 3px;
  pointer-events: none;
}

.saveDisabledMessageContainer {
  display: none;
  position: fixed;
  border: 2px solid lightgray;
  background: whitesmoke;
  color: black;
  border-radius: 8px;
  min-width: 140px;
  margin-left: -1px;
  white-space: nowrap;
  z-index: 10000;
  margin-top: 30px;
}

.buttonDisabled:hover .saveDisabledMessageContainer {
  display: block;
}

.saveDisabledMessage {
  margin-left: 18px;
  margin-right: 18px;
  margin-top: 4px;
  text-align: left;
}

.saveDisabledMessageHeader {
  margin-left: 18px;
  margin-right: 18px;
  margin-top: 4px;
  text-align: left;
  font-weight: 700;
}

.divider {
  border-top: 1px solid lightgray;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: -2px;
  margin-bottom: 9px;
}
