.dialogContainer {
  height: 100%;
  width: 100%;
}

.modalHeader {
  width: 100%;
  height: 55px;
  background: #3783c5;
}

.buttonWhite {
  float: right;
  color: darkslategrey;
  margin: 12px;
  margin-right: 30px;
  height: 30px;
  border: 2px solid white;
  border-radius: 8px;
  width: 140px;
  background: white;
  text-align: center;
  cursor: pointer;
}

.text {
  margin-top: 3px;
  pointer-events: none;
}

.header {
  display: flex;
  flex-direction: row;
  float: left;
  width: 100%;
}

.headerImage {
  height: 55px;
  margin-left: 20px;
}

.headerText {
  color: white;
  font-size: 20px;
  padding-top: 15px;
  float: left;
  margin-left: 25px;
  width: 100%;
}

.threeWayDialogButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  justify-content: center;
  align-content: center;
  align-items: center;
  height: 120px;
}

.textarea {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: stretch;
  align-items: stretch;
  height: calc(100% - 89px);
  width: 100%;
}

.text {
}

.iconContainer {
  color: white;
  margin: 7px;
}
