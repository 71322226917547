.container {
  position: fixed;
  z-index: 99999;
  bottom: 0;
  right: 0;
  padding: 3px;
  background-color: LightYellow;
  border-top: solid 1px lightgray;
  border-left: solid 1px lightgray;
}

.refreshBtn {
  color: red;
}

.refreshBtn:hover {
  cursor: pointer;
  text-decoration: underline;
}
