.smaller {
  width: 600px;
  margin: 150px;
}

.mountContainer {
  height: 100vh;
  width: 100vw;
}

.mountContainer img {
  user-select: none;
}

.appContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.contentContainer {
  flex: 0 0 auto;
  width: 100%;
  height: calc(100% - 65px);
  display: flex;
  flex-direction: row;
}

:global .backgroundColorBlue {
  background-color: #3783c5;
}

:global .backgroundColorGray {
  background-color: #f6f5f4;
}

:global .expiredItemColorRed {
  background: #ffdbdb !important;
}

:global .focus-border:focus {
  outline: 0px solid white;
  border-color: #719ece;
  box-shadow: 0 0 10px #719ece;
}

/* body {
  overflow: hidden;
} */

:global .inputFocus:focus {
  outline: 0px solid white;
  background-color: rgb(225, 225, 225);
}

:global .boxShadow {
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
}

.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.errorCenterBox {
  border: 2px solid darkred;
  border-radius: 5px;
  box-shadow: 0px 1px 2px 2px lightgray;
  background-color: red;
  color: white;
  padding: 20px;
}

:global .leaflet-editing-icon {
  border-radius: 50% !important;
}
