.hide {
  /* visibility: hidden; */
  display: none;
}

.show {
  visibility: visible;
}

.container {
  display: inline-flex;
}

.userNameText {
  margin-top: 7px;
  margin-right: 10px;
  float: left;
  user-select: none;
  cursor: default;
}

.loginText {
  margin-right: 10px;
  font-size: small;
}

.whiteText {
  color: white;
}

.grayText {
  color: darkgray;
}

.dropdownContainer {
  margin-left: auto;
}

.dropdownButtonOpen {
  /* float: right; */
  /* border: solid 1px black; */
  /* border-radius: 5px; */
  /* margin: 3px;
  padding: 3px; */
  cursor: pointer;
}

.dropdownButtonClose {
  /* float: right; */
  /* border: solid 1px black; */
  /* border-bottom: 0; */
  /* border-radius: 5px; */
  /* margin: 3px;
  margin-bottom: 0;
  padding: 3px;
  padding-bottom: 6px; */
  cursor: pointer;
}

.dropdownPanel {
  position: absolute;
  z-index: 10000;
  background-color: white;
  right: 10px;
  min-width: 300px;
  top: 58px;
  border: 1px solid lightgray;
}

.dropdownPanelArrow {
  position: absolute;
  top: 52px;
  right: 37px;
  height: 12px;
  width: 12px;
  background: white;
  z-index: 10001;
  transform: rotate(45deg);
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
}

.userInfoContainer {
  height: 25px;
  margin-left: 25px;
}

.panelContent {
  display: flex;
}

.listItemText {
  margin-left: 10px;
  font-weight: 700;
}

.listItemFont {
  font-weight: 700;
}

.loggedInLogo {
  height: 35px;
}

.tosStyle {
  height: 32px;
  margin-left: 20px;
  align-items: center;
  font-weight: 700;
  margin-bottom: 10px;
}

.buttonContainer {
  height: 45px;
  user-select: none;
}

.buttonContainer:hover {
  cursor: pointer;
  text-decoration: underline;
}

.buttonText {
  margin-top: 5px;
  margin-left: 5px;
  pointer-events: none;
  font-weight: 700;
}

.buttonWithSubtextText {
  margin-top: 2px;
  margin-left: 5px;
  pointer-events: none;
  font-weight: 700;
}

.nameAndLogo {
  display: flex;
  justify-content: space-between;
  margin-right: 4px;
  margin-bottom: -10px;
}

.button {
  display: flex;
  padding-top: 5px;
  align-items: center;
}

.buttonIcon {
  margin-left: 15px;
  margin-right: 10px;
  height: 25px;
}

.nameText {
  margin-top: 10px;
  margin-left: 15px;
  font-weight: 700;
}

.emailText {
  color: darkGray;
  margin-top: -20px;
  margin-left: 20px;
}

.divider {
  border-top: 1px solid lightgray;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: -2px;
  margin-bottom: 9px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.buttonSubText {
  margin-top: -15px;
  margin-left: 15px;
  color: darkgray;
}

.flagContainer {
  display: flex;
  flex-direction: row;
}

.flagButton {
  margin-left: 15px;
  height: 35px;
  cursor: pointer;
}

.flagButtonSelected {
  margin-left: 15px;
  height: 35px;
  cursor: pointer;
  background: #3783c5;
}
