.messageContainer {
  background: #fffc5e;
  height: 90px;
  border: solid 2px red;
  overflow-y: auto;
}

.messageTitle {
  font-weight: bold;
  margin-left: 50px;
  font-size: 14px;
}

.messageText {
  margin-left: 50px;
  font-size: 12px;
}

.messageAuthor {
  font-style: italic;
  margin-left: 50px;
  font-size: 12px;
}
.messageContent {
  flex-direction: row;
  display: flex;
}

.titleText {
  margin-right: 5px;
  margin-left: 5px;
  font-weight: bold;
}

.organizationText {
  font-weight: bold;
  font-size: 14px;
}

.organizationContainer {
  margin-left: 5px;
  margin-top: 2px;
  border-bottom: 1px solid #444;
}
