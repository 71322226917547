.buttonPanelButtonSelectedDark {
  cursor: pointer;
  color: white;
  text-align: center;
  min-width: 120px;
  margin-left: 5px;
  border-radius: 8px;
  border: 1px solid lightgray;
  background-color: #3783c5;
}
.buttonPanelButtonDark {
  color: white;
  border: 2px solid white;
  border-radius: 8px;
  min-width: 140px;
  text-align: center;
  cursor: pointer;
  background: #3783c5;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.buttonPanelButtonSelected {
  cursor: pointer;
  color: #f5f5f5;
  text-align: center;
  min-width: 120px;
  margin-left: 5px;
  border-radius: 8px;
  border: 1px solid lightgray;
  background-color: #1386af;
}
.buttonPanelButtonSelected:focus {
  outline: none;
}

.buttonPanelButtonSelected:hover {
  border: 1px solid lightgray;
}

.buttonPanelButton {
  display: flex;
  justify-content: center;
  cursor: pointer;
  min-width: 70px;
  margin-left: 5px;
  color: darkcyan;
  border: 1px solid darkgray;
  background: white;
  border-radius: 8px;
}
.buttonPanelButton:hover {
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 8px;
}

.buttonPanelButtonDisabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.item:hover .validationErrorMessageContainer {
  display: block;
}
.item {
  margin: 5px 7px;
}
@-moz-document url-prefix() {
  .item:focus {
    outline: 1px dotted black;
    outline-offset: none;
  }
}
.buttonPanelButtonText {
  margin: 2px 15px 0px 15px;
  white-space: nowrap;
  user-select: none;
}

.iconContainer {
  padding-right: 5px;
  color: gray;
  font-size: 16px;
}
